@import "./animate";
@import "./variables";
@import "./style";

.back-btn {
  position: absolute;
  top: -85px;
  right: 25px;
}

.btn-outline-primary {
  color: $themeColor !important;
  border-color: $themeColor !important;
  background: transparent !important;
}



.carousel-inner .carousel-item img {
  object-fit: cover;
}

.content h2 {
  letter-spacing: 0px;
  color: #32325D;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.arrow {
  background: transparent !important;
  outline: none;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 9999999999;

  i {
    color: #fff;
  }
}

.truncate {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-black {
  color: black;
}

.accordion-item:not(:first-of-type) {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item .accordion-button {
  border-radius: 0.25rem;
}

.accordion-button::after {
  color: rgba(246, 247, 250, 0.003);
}

.accordion-button {
  font-size: 14px;
  padding: 10px 20px;
}

.edit-icon {
  position: absolute;
  right: 60px;
  opacity: 0.9;
  // color: rgb(23, 106, 240);
}